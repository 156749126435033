<template>
	<div>
		<header>
			<ul>
				<li>
					<div class="tab-box">
						<button class="btn btn-link" @click="createNewProject">
							<font-awesome-icon icon="plus" :style="{ width: '20px', height: 'auto' }" />
							New Project
						</button>
					</div>
				</li>
				<li>
					<div class="tab-box">
						<router-link to="/system-users">
							<font-awesome-icon icon="user" :style="{ width: '20px', height: 'auto' }" />
							System users
						</router-link>
					</div>
				</li>
				<li>
					<div class="tab-box">
						<button class="btn btn-link" @click="logout">
							<font-awesome-icon icon="sign-out-alt" :style="{ width: '20px', height: 'auto' }" />
							Logout
						</button>
					</div>
				</li>
			</ul>
		</header>
		<div class="content-wrapper">
			<div class="loading-container" v-if="isLoading">
				<div class="card">
					<div class="card-body">
						<div class="spinner-border"></div>
						<h6 style="margin-top: 15px;">{{ loadingMessage }}</h6>
					</div>
				</div>
			</div>
			<div class="" v-if="!isLoading">
				<div class="no-project" v-if="loadingError">
					<font-awesome-icon icon="database" :style="{ width: '50px', height: 'auto', marginBottom: '10px', fill: '#09568d' }" />
					<h5>Error Loading Content</h5>
					<button class="btn btn-custom" style="margin-top: 7px;" @click="loadProject">Retry Loading Content</button>
				</div>
				<div class="no-project" v-if="!loadingError && projectList.length === 0">
					<font-awesome-icon icon="database" :style="{ width: '50px', height: 'auto', marginBottom: '10px', fill: '#09568d' }" />
					<h5>No Project Is Avaliable</h5>
					<button class="btn btn-custom" style="margin-top: 7px;" @click="createNewProject">Create New Project</button>
				</div>
				<div class="project-container" v-if="!loadingError && projectList.length !== 0">
					<div class="container">
						<h3 style="font-weight: 750; margin-bottom: 20px;">Project List</h3>
						<div class="row">
							<div class="col-6 col-sm-4 col-md-3" v-for="(item, i) in projectList" :key="i">
								<div class="card">
									<div class="card-header"></div>
									<div class="card-body">
										<h5>{{ item.project_name }}</h5>
										<button class="btn btn-primary" @click="openProject(item.project_folder)">Open</button>
										<button class="btn btn-danger" @click="deleteProject(item.project_folder)">Delete</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" ref="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
						<button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="alert alert-danger alert-dismissable" v-if="showErrorAlert">
							{{ errorMessage }}
						</div>
						<form @submit.prevent="onSubmit" novalidate>
							<div class="form-group mb-2">
								<label for="">Project Name</label>
								<input type="text" v-model="vv.project_name.$model" placeholder="Enter Project Name" class="form-control">
								<span class="error">{{ vv?.project_name?.$errors[0]?.$message }}</span>
							</div>
							<div class="form-group">
								<button class="btn btn-primary" :disabled="vv.$invalid" style="margin-right: 15px;" v-if="editMode">Update</button>
								<button class="btn btn-primary" :disabled="vv.$invalid" v-if="!editMode">
									<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									<span v-if="isProcessing" style="padding-left: 15px !important;">Creating..</span>
									<span v-if="!isProcessing">Create</span>
								</button>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" @click="modal.hide()">Close</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive } from "vue";
import { projectService } from '@/services/project.service'
import { Modal } from 'bootstrap'
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { storageService } from '@/services/storage.service'

export default {
	name: 'ProjectPanel',
	setup() {
		const fform = reactive({
			project_name: ''
		})
		const rules = {
			project_name: { required }
		}
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			isLoading: true,
			modal: null,
			modalTitle: '',
			editMode: false,
			isProcessing: false,
			loadingError: false,
			isProjectAvaliable: false,
			loadingMessage: 'Loading Project......',
			showErrorAlert: false,
			errorMessage: '',
			projectName: '',
			projectList: []
		};
	},
	mounted () {
		this.modal = new Modal(this.$refs.exampleModal)
		this.loadProject()
	},
	methods: {
		loadProject () {
			this.loadingError = false
			this.loadingMessage = 'Loading Project......'
			projectService.fetchProject()
				.then((response) => {
					this.isLoading = false
					if(parseInt(response.data.message_code) === 200){
						this.projectList = response.data.project_list
					}else{
						this.loadingError = true
					}
				})
				.catch((error) => {
					this.loadingError = true
					console.log(error.message)
				})
		},
		async createNewProject () {
			this.modalTitle = 'Create Project'
			this.modal.show()
		},
		async onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;
			//console.log(this.fform.project_name)
			this.isProcessing = true
			this.showErrorAlert = false
			const postData = {
				project_name: this.fform.project_name
			}
			projectService.createProject(postData)
				.then((response) => {
					this.isProcessing = false
					if(parseInt(response.data.message_code) === 200){
						this.fform.project_name = ''
						this.modal.hide()
						this.isLoading = true
						this.loadProject()
					}else{
						this.showErrorAlert = true
						this.errorMessage = response.data.message
					}
				})
				.catch((error) => {
					this.showErrorAlert = true
					this.errorMessage = 'Error connecting to the server'
					console.log(error.message)
				})
		},
		async openProject (folderPath) {
			this.$router.push(`/panel/${folderPath}/`)
		},
		async deleteProject (folderPath) {
			this.$swal({
				text: `Are you sure to delete this project?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, Delete',
				allowOutsideClick: false
			}).then((responses) => {
				if(responses.isConfirmed) {
					this.loadingError = false
					this.isLoading = true
					this.loadingMessage = 'Deleting Project.......'
					const postData = {
						folder_name: folderPath
					}
					projectService.deleteProject(postData)
						.then((response) => {
							this.isLoading = false
							if(parseInt(response.data.message_code) === 200){
								this.loadProject()
							}else{
								this.$swal({
									text: response.data.message,
									icon: 'error'
								})
							}
						})
						.catch((error) => {
							this.isLoading = false
							console.log(error.message)
							this.$swal({
								text: 'Error connecting to server',
								icon: 'error'
							})
						})
				}
			})
		},
		logout () {
			storageService.removeLogin()
			setTimeout(() => {
				this.$router.push('/')
			}, 200)
		}
	}
};
</script>

<style scoped>
header{
	display: flex;
	justify-content: flex-end;
	/*background: #5cbaea;*/
	background: #09568d;
	/*box-shadow: 0px 0px 20px 6px #1e6e96 inset;*/
	box-shadow: 0px 0px 20px 6px #76a0c3 inset;
	padding: 10px 5px;
	height: 70px;
}
header ul li{
	display: inline-block;
	text-transform: capitalize;
	padding: 10px 7px;
	min-width: 100px;
}
header ul li .tab-box a, header ul li .tab-box .btn{
	display: flex;
	flex-direction: row;
	justify-content: center;
	text-decoration: none !important;
	align-items: center;
	/*color: #36383a;*/
	color: #fbf8f8;
	margin-right: 15px;
}
header ul li .tab-box a svg, header ul li .tab-box .btn svg{
	margin-right: 10px;
}
.content-wrapper{
	background-color: #f4f5f8;/*#ebedf1;*/
	width: 100%;
	min-height: calc(100vh - 70px);
	padding-top: 70px;
}
.loading-container, .no-project{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: calc(100vh - 140px);
}
.loading-container .card{
	border-radius: 10px;
}
.loading-container .card .card-body{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.project-container{
	padding-bottom: 70px;
}
.project-container .container .col-6{
	margin-bottom: 20px;
}
.project-container .container .col-6 .card{
	border-radius: 10px !important;
}
.project-container .container .col-6 .card .card-header{
	height: 150px;
	background-color: #678fac;
	border-top-right-radius: 10px !important;
	border-top-left-radius: 10px !important;
	background-image: url('../image/folder.png');
	background-repeat: no-repeat;
	background-size: 60%;
	background-position: center;
}
.project-container .container .col-6 .card .card-body{
	padding-left: 10px !important;
	padding-right: 10px !important;
}
.project-container .container .col-6 .card .card-body h5{
	font-size: 15px;
	font-weight: 650;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 98%;
	display: block;
}
.project-container .container .col-6 .card .card-body .btn{
	font-size: 13px;
	padding: 1px 7px !important;
	border-radius: 5px !important;
	margin-right: 7px;
	height: 27px !important;
}
</style>